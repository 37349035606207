// This file contains the Data Layer Events
// that are emitted to Google Analytics via the Google Tag Manager
// This expects data attributes to be set on the a elements like this:
// data-event='e_recommended_resource' data-resource_title='Learn the 3 key advantages'

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

interface AttributeData {
  [key: string]: string;
}

export const tracking = () => {
  window.dataLayer = window.dataLayer || [];

  //listen for link click events at the document level
  if (document.addEventListener) {
    document.addEventListener('click', interceptClickEvent);
  }

  function interceptClickEvent(e: MouseEvent) {
    console.log('interceptClickEvent');
    let href;
    const location = { location: window.location.pathname };
    let data:DOMStringMap;
    const target = e.target as HTMLElement;
    if (target) {
      const htmlAnchorElement = target.closest('a');
      // console.log('htmlAnchorElement', htmlAnchorElement ? htmlAnchorElement.dataset : 'not an a tag');
      if (htmlAnchorElement) {
        href = { href: target.getAttribute('href') };
        // check to see if there are data-event attributes
        if (htmlAnchorElement.dataset.event) {
          // exclude empty dataset values
          data = htmlAnchorElement.dataset;
          // iterate over the object and remove any empty values
          Object.keys(data).forEach((key) => {
            if (!data[key]) {
              delete data[key];
            }
          });
          // console.log('data', data);
        } else {
          console.log('no data-event', htmlAnchorElement.innerHTML ? htmlAnchorElement.innerHTML : htmlAnchorElement);
          // here we set the default data attributes for the event
          // since the link didn't have any set. This is a fallback.
          data = {
            event: 'non_tagged_a_link',
            link_text: htmlAnchorElement.innerHTML ? htmlAnchorElement.innerHTML : 'no link text available',
          };
        }
        let trackedData = Object.assign({}, data);
        // add href to trackedData object
        trackedData = Object.assign(trackedData, href, location);
        window.dataLayer.push(trackedData);
        console.log('tracking.ts: tracked click', trackedData);
      }

    }
  }
};
export default tracking;
