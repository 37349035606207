window.addEventListener('load', () => {
    const editLinks = document.querySelectorAll('[data-edit-link]');
    const delayIncrement:number = 20;

    const processLink = (index: number) => {
        if (index >= editLinks.length) return; // Exit if no more links to process

        const editLink = editLinks[index];
        const id = editLink.getAttribute('data-edit-link');

        if (id && parseInt(id)) {
            setTimeout(() => {
                fetch('/actions/entry-editor-links/entry-processor/cp-link?id=' + id)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error('Server responded with a non-OK status');
                        }
                        return response.json();
                        console.log(response);
                    })
                    .then((data) => {
                        if (data && data.success && data.message) {
                            const link = document.createElement('a');
                            link.setAttribute('href', data.message);
                            link.innerText = '📝';
                            // link.style.cssText = 'background:black;color:white;position:fixed;bottom:20px;left:20px;padding:1rem;';
                            // link.setAttribute('target', '_blank');
                            link.setAttribute('class', 'text-xl no-underline absolute top-2 right-2');
                            editLink.appendChild(link);
                            processLink(index + 1); // Continue with the next link
                        } else {
                            // If data object is invalid or missing necessary properties
                            console.warn('Entry edit links: link creation not required');
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        // Stop further processing if there's an error
                        return;
                    });
            }, delayIncrement * index);
        } else {
            processLink(index + 1); // Continue to the next link if current one is invalid
        }
    };

    processLink(0); // Start processing from the first link
});
