// Accept HMR as per https://vitejs.dev/guide/api-hmr.html#accepting-updates
/// <reference types="vite/client" />
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR update');
    });
}

import Alpine from 'alpinejs'

// @ts-ignore
window.Alpine = Alpine

import './entry-editor-links';

// Import our CSS
import '@/css/app.pcss';
import Prism from "prismjs";

Prism.highlightAll();

// Import the component
import {ProgressiveShareButton} from 'progressive-share-button';
// Initialize the component
ProgressiveShareButton();

import tracking from './tracking';

tracking();

// import {fetchEntryEditUrl, EntryEditUrlResponse, createEditLinkElement} from './admin-links';


// App main
const main = async () => {
    const day = new Date().getDay();

    const dayColors = [
        {
            day: 'Sunday is a day of rest',
            logoBrand: '#FBD102',
            logoHover: '#F5E50F',
            circuit: '#98AD07',
            badgeDark: '#926D07', // old version: #E3A809
            textColor: '#8E6A06', // old version: #E3A809
        },
        {
            day: 'Monday is a day to get back to work',
            logoBrand: '#02c3e6',
            logoHover: '#05d9ff',
            circuit: '#117F97',
            badgeDark: '#117F97',
            textColor: '#026d80',
        },
        {
            day: 'Tuesday is when things happen',
            logoBrand: '#FE7F71', // old version: #fc1d14
            logoHover: '#fe08e2',
            circuit: '#FE5808',
            badgeDark: '#E6075D',
            textColor: '#A52103', //#A52103 old version: #E62F07
        },
        {
            day: 'Wednesday is when you\'re halfway there',
            logoBrand: '#17e4d3',
            logoHover: '#0CF05C',
            circuit: '#0CFAA3',
            badgeDark: '#077992', // old version: #0CC0E8
            textColor: '#067189', // old version: #0C89F0
        },
        {
            day: 'Thursday is when you sprint to the finish',
            logoBrand: '#FD5002',
            logoHover: '#FF110A',
            circuit: '#FF9D0A',
            badgeDark: '#BB5B07', // old version: #F7770A
            textColor: '#AF2408', // old version: #E62F09
        },
        {
            day: 'Friday is when you see the light',
            logoBrand: '#E346FA',
            logoHover: '#FF47D0',
            circuit: '#B352F5',
            badgeDark: '#5247FF', // old version: #5247FF
            textColor: '#733EDE', // old version: #733EDE
        },
        {
            day: 'Saturday is when you play',
            logoBrand: '#F0DE0C',
            logoHover: '#E9FA0C',
            circuit: '#0CF020',
            badgeDark: '#067F12', // old version: #089616
            textColor: '#1B6006', // old version: #33BD09
        },
    ];

    console.log(dayColors[day].day);

    const colorSet = dayColors[day]; //day

    // logo  hover - pink
    document.documentElement.style.setProperty(
        '--color-primary1',
        colorSet.logoHover,
    );

    // logo without hover - orange
    document.documentElement.style.setProperty(
        '--color-primary2',
        colorSet.logoBrand,
    );

    // circuit start color - yellow
    document.documentElement.style.setProperty(
        '--color-primary3',
        colorSet.circuit,
    );

    // date tag - cornsilk
    document.documentElement.style.setProperty(
        '--color-primary4',
        colorSet.badgeDark,
    );

    // headlines
    document.documentElement.style.setProperty(
        '--color-primary5',
        colorSet.textColor,
    );

    Alpine.start()
}

// Execute async function`
main().then(() => {
    // Open external links in a new window
    function externalLinks() {
        const c = document.getElementsByTagName('a')
        let a = 0;
        for (;
            a < c.length;
            a++
        ) {
            const b = c[a];
            b.getAttribute('href') &&
            b.hostname !== location.hostname &&
            (b.target = '_blank');
        }
    }

    externalLinks();
});
